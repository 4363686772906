import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/edition',
    props: true,
    name: 'edition',
    component: () => import('../components/Edition.vue')
  }, 
  {
    path: '/booklet',
    props: true,
    name: 'booklet',
    component: () => import('../components/booklet/BookletPage.vue')
  }, 
  {
    path: '/',
    name: 'login',
    component: () => import('../components/shared/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../components/companies/CompanyRegistration.vue')
  }, 
  {
    path: '/company/:companyId',
    props: (route) => {
      return { ...route.params, ...{ companyId: Number.parseInt(route.params.companyId, 10) || undefined }}
    },
    name: 'company',
    component: ()=>import('../components/companies/Company.vue')
  },
  {
    path: '/companies',
    props: true,
    name: 'companies',
    component: ()=>import('../components/companies/Companies.vue')
  },
  {
    path: '/companies/:companyId/edit',
    props: (route) => {
      return { ...route.params, ...{ companyId: Number.parseInt(route.params.companyId, 10) || undefined }}
    },
    name: 'companyEdit',
    component: ()=>import('../components/companies/CompanyEdit.vue')
  },
  {
    path: '/projects/new',
    name: 'projectsNew',
    component: () => import('../components/projects/ProjectCreate.vue')
  },
  {
    path: '/companies/:companyId/projects/:projectId/edit',
    props:true,
    name: 'edit',
    component: () => import('../components/projects/Edit.vue')
  },
  {
    path: '/company/submission',
    name: 'companysubmission',
    component: () => import('../components/companies/CompanySubmission.vue')
  }, 
  {
    path: '/project',
    props: true,
    name: 'project',
    //TODO: change view to comp
    component: () => import('../views/StudentProjectView.vue')
  },
  {
    path: '/students',
    props: true,
    name: 'students',
    component: ()=>import('../components/Students.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

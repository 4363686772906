<template>
    <div v-if="this.$store.state.loginInfo.role == 'ROLE_ADMIN'">
        <div class="edition_nav">
            <p><router-link :to="{ name: 'edition' }">{{
                this.$i18n.t("nav.dates") }}</router-link>
            </p>
            <p><router-link :to="{ name: 'booklet'}">{{
                this.$i18n.t("nav.booklet") }}</router-link>
            </p>
            <p><router-link :to="{ name: 'companies'}">{{
                this.$i18n.t("nav.companies") }}</router-link>
            </p>
            <p><router-link :to="{ name: 'students'}">{{
                this.$i18n.t("nav.students") }}</router-link>
            </p>
        </div>
    </div>
    <div v-else-if="this.$store.state.loginInfo.role == 'ROLE_COMPANY'">
        <div class="company_nav">
            <p><router-link :to="{ name: 'projectsNew' }">{{ this.$i18n.t("nav.cform") }}</router-link></p>
            <p><router-link
                    :to="{ name: 'company', params: { companyId: this.$store.state.loginInfo.id} }">{{
                        this.$i18n.t("nav.myproj") }}</router-link>
            </p>
        </div>
    </div>
    <div v-else-if="this.$store.state.loginInfo.role == 'ROLE_STUDENT'">
        <div class="company_nav">
            <p><router-link :to="{ name: 'booklet' }">{{ this.$i18n.t("nav.booklet") }}</router-link></p>
            <p><router-link :to="{ name: 'project' }">{{ this.$i18n.t("nav.projects") }}</router-link></p>
        </div>
    </div>
</template>

<script>
export default {
    name: "NavigationBar",
    //TODO: FIXME: this is useless
    data() {
        return { projectYear: "2024"}
    }

}
</script>

<style scoped>
.edition_nav {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    height: 5vh;
}

.company_nav {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 5vh;
}

.edition_nav>*,
.company_nav>* {
    border-right-style: solid;
    padding-top: 1vh;
    margin-bottom: 0;
}

.edition_nav> :nth-child(1),
.company_nav> :nth-child(1) {
    border-left-style: solid;
}

a {
    color: black;
    text-decoration: none;
}

select {
    display: grid;
    margin-bottom: 25%;
    grid-template-columns: 2fr 1fr;
    width: 8em;
    border-style: solid;
    border-color: black;
    background-color: white;
    height: 3.5em;
}

button:hover {
    cursor: pointer;
}
</style>

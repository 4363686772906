import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import axios from "axios";

import backendEndpoint from '../api'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

let backend = ""
if (process.env.NODE_ENV != 'development') {
  backend = process.env.VUE_APP_SERVER
}

const store = new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: {
    loginInfo: {
      login: false,
      username: undefined,
      token: undefined,
      role: undefined,
    },
    edition: {},
    language: "en",
  },
  getters: {
    getProjects: (state) => () => {
      return state.projects;
    },

    getLoginInfo: (state) => () => {
      return state.loginInfo
    },

    getLanguage: (state) => () => {
      return state.language
    },

    edition: (state) => () => {
      return state.edition
    }
  },
  mutations: {
    setLoginInfo(state, loginInfo) {
      state.loginInfo = loginInfo;
    },

    setLanguage(state, language) {
      state.language = language;
    },

    setEdition(state, edition) {
      state.edition = edition
    }
  },
  actions: {
    /**
     * get all the projects from the database
     */
    async fetchProjectsByEdition(context, edition) {
      const res = await backendEndpoint.get(`edition/${edition.projectYear}/projects`, {
        token: context.getters.getLoginInfo().token
      })
      return res.data
    },

    async fetchProject(context, projectId) {
      const res = await backendEndpoint.get(`/projects/${projectId}`, {
        token: context.getters.getLoginInfo().token
      })
      return await res.data
    },


    async projectsOfCompany(context, companyId) {
      const res = await backendEndpoint.get(`/companies/${companyId}/projects`, {
        token: context.getters.getLoginInfo().token
      })
      return res.data
    },
    /**
     * Add new project to the database
     * 
     * @param {object} project the project being submitted
     */
    async addProject(context, project) {
      const res = await backendEndpoint.post("/projects",
      project,
       { token: context.getters.getLoginInfo().token })
    },

    /**
     * Delete a project from the database
     * 
     * @param {object} project the project being deleted 
     */
    async deleteProject(context, project) {
      const response = await backendEndpoint.delete(`/projects/${project.id}`,
      {token: context.getters.getLoginInfo().token})
      return context.dispatch("fetchProjects");
    },
    /**
     * Update a project
     * 
     * @param {object} project the project being updated
     */
    async editProject(context, project) {
      const res = await backendEndpoint.put(`/projects/${project.id}`, 
      project,
      {token: context.getters.getLoginInfo().token})
      return context.dispatch("fetchProjects");
    },




    // Login
    /**
     * Log the user in and save them in the loginInfo
     * 
     * @param {object} user the user attempting to log in
     */
    async login(context, user) {
      const res = await backendEndpoint.post(`/login`, user)
        .catch(e => console.error(e));
      const response = res.data
      const parts = response.toString().split('.');
      if (parts.length !== 3) {
        throw new Error('Invalid JWT format');
      }
      const decodedPayload = atob(parts[1]);
      const payload = JSON.parse(decodedPayload);
      let loginInfo = {
        login: true,
        username: payload.sub,
        token: response.toString(),
        role: payload.role[0].role,
        id: payload.id
      }
      context.commit("setLoginInfo", loginInfo)
      await context.dispatch("fetchEdition", new Date().getFullYear())

    },

    /**
     * Log the student in and save them in the loginInfo
     * 
     * @param {object} user the user attempting to log in
     */
    async studentLogin(context, user) {
      const res = await backendEndpoint.post('/student/login', 
      user.id).catch(e => console.error(e));
      let response = await res.data
      let loginInfo = {
        login: true,
        username: user.username,
        token: response.toString(),
        role: "ROLE_STUDENT"
      }
      context.commit("setLoginInfo", loginInfo)
    },

    async changeRegistrationData(context, data) {
      const {userId, body, needNewLogin} = data
      const response = await backendEndpoint.patch( `/update-registration/${userId}`, body,
      {token: context.getters.getLoginInfo().token
      })
      if (needNewLogin) {
        let loginInfo = {
          login: true,
          username: email,
          token: response.data.toString(),
          role: "ROLE_COMPANY",
          id: userId
        }
        context.commit("setLoginInfo", loginInfo)
      }
    },

    /**
     * Logout the current user
     */
    async logout(context) {
      const token = context.getters.getLoginInfo().token
      let loginInfo = {
        login: false,
        username: undefined,
        token: undefined,
        role: undefined
      }
      context.commit("setLoginInfo", loginInfo)
      context.commit("setEdition", undefined)
      
      await backendEndpoint.post("/logout", null,
      {  token: token })
        .catch(e => console.error(e));

    },

    // Company
    /**
     * get all the companies in the database
     */
    async fetchCompanies(context) {
      const res = await backendEndpoint.get("/companies", 
      {token: context.getters.getLoginInfo().token
      })
      return res.data;
    },

    //fetches all companies with the relevant edition projects
    async fetchCompaniesWithProjectsForEdition(context, edition) {
      const res = await backendEndpoint.get(`edition/${edition.projectYear}/companies`,
      {token: context.getters.getLoginInfo().token
      })
      return res.data;
    },

    async fetchCompany(context, companyId) {
      const res = await backendEndpoint.get(`/companies/${companyId}`, 
      {token: context.getters.getLoginInfo().token
      })
      let company = await res.data
      return company;
    },
    /**
     * Register a company
     * 
     * @param {object} company the company being registered
     */
    async registerCompany(_, company) {
      const res = await backendEndpoint.post("/companies/register", company).catch(e => console.error(e));
      return res
    },

    /**
     * Register a company
     * 
     * @param {object} company the company being registered
     */
    async updateCompany(context, company) {
      const res = await backendEndpoint.put("/companies/edit", company,
      {token: context.getters.getLoginInfo().token}
      ).catch(e => console.error(e));
      return res
    },


    async disableCompany(context, id) {
      const res = await backendEndpoint.patch(`/disable/${id}`, null,
      { token: context.getters.getLoginInfo().token 
      })
      return res
    },

    async enableCompany(context, id) {
      const res = await backendEndpoint.patch(`/enable/${id}`, null,
      { token: context.getters.getLoginInfo().token 
      })
      return res
    },

    async fetchEdition(context, projectEdition) {
      const response = await backendEndpoint.get(`/edition/${projectEdition}`,
        { token: context.getters.getLoginInfo().token }
      ).catch(e => console.log(e))
      let edition = {}
      if (response.status === 200) {
        edition = await response.data
      }
      context.commit("setEdition", edition)
      return edition;
    },

    async postYear(context, edition) {
      const response = await backendEndpoint.post("/edition",
        edition,
        { token: context.getters.getLoginInfo().token })
      return context.dispatch("fetchEdition", edition.projectYear);
    },

    async updateYear(context, edition) {
      const response = await backendEndpoint.put("/edition",
        edition,
        { token: context.getters.getLoginInfo().token })
      return context.dispatch("fetchEdition", edition.projectYear);
    },


    //Pdf
    async createPdf(context, params) {
      const { year, pdf } = params
      const formData = new FormData()
      formData.append('pdf', pdf)
      await axios.post(`${backend}/api/messages/pdf/${year}`, formData,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
            "Authorization": `Bearer ${context.getters.getLoginInfo().token}`
          }
        }
      );
      return context.dispatch("fetchEdition", year);
    },

    async downloadPdf(context, year) {
      const response = await axios.get(`${backend}/api/messages/pdf/${year}`,
        {
          headers: {
            "Authorization": `Bearer ${context.getters.getLoginInfo().token}`
            ,
            responseType: 'arrayBuffer'
          }
        }
      )
      return response.data;
    },

    // Students
    /**
     * Get all the students of a certain year
     * 
     * @param {String} projectYear the year
     */
    async fetchStudents(context, projectYear) {
      const res = await backendEndpoint.get(`/students/${projectYear}`, 
      {token: context.getters.getLoginInfo().token
      })
      let students = await res.data
      return students;
    },

    /**
     * Add a new student to the database
     * 
     * @param {object} student the student being added
     */
    async postStudent(context, student) {
      const res = await backendEndpoint.post("/students", 
      student,
      {token: context.getters.getLoginInfo().token
      })
      return res.status === 200
    },

    /**
     * Update a student
     * 
     * @param {object} student the student
     */
    async updateStudent(context, student) {
      const res = await backendEndpoint.put('/students', {
        token: context.getters.getLoginInfo().token
      })
      return res.status === 200
    },
    /**
     * Deactivate a student from the database
     * 
     * @param {object} student the student being deactivate 
     */
    async deleteStudent(context, student) {
      //remove student
      await backendEndpoint.patch(`/disable/${student.id}`, null, {
        token: context.getters.getLoginInfo().token
        })
      return context.dispatch("fetchStudents", student.projectYear);
    },
  }
})

export default store;
import { createApp, initCustomFormatter } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { createI18n } from "vue-i18n";
import en from "./locales/en.json";
import it from "./locales/it.json";
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
// import axios from 'axios';
// import VueAxios from 'vue-axios'
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";



export const i18n = createI18n({
  locale: "en",
  fallbackLocale: "it",
  messages: { it, en },
});

const options = {
  config: {
    url: 'https://auth.dev.si.usi.ch/auth/',
    realm: 'field-project-atelier-manager',
    clientId: 'field-project-atelier-manager',
  },
  init: {
    onLoad: "",
    enabled: false
  }
}

const toastOptions = {}

createApp(App)
  // .use(VueAxios, axios)
  .use(Toast, toastOptions)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueKeyCloak, options)
  .mount('#app')
<template>
  <div class="header">
      <div class="logo">
          <router-link :to="{ name: 'login' }">
              <img alt="Vue logo" src="@/assets/logo.png">
          </router-link>
      </div>
      <div class="head">
          <router-link :to="{ name: 'login' }">
              <div class="title">Software Atelier 5</div>
              <div class="subtitle">Field Project</div>
          </router-link>
      </div>
      <div>
          <button class="logout" v-if="this.$store.state.loginInfo.login == true" v-on:click="logout()">
              <p>Logout</p><img src="@/assets/logout.png">
          </button>
          <language-switcher />
      </div>
  </div>
</template>

<script>
import LanguageSwitcher from './LanguageSwitcher.vue';

export default {
  name: "FpamHeader",
  methods: {
      /*
       * Called on logout to log out the user
       */
      async logout() {
          await this.$store.dispatch("logout").then(() => {
              this.$router.push('/');
          });
      },
  },
  components: { LanguageSwitcher }
}
</script>

<style scoped>
.header {
  margin-top: 3vh;
  margin-bottom: 1vh;
  font-weight: Bold;
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
}

a {
  color: black;
  text-decoration: none;
}

hr {
  color: black;
  margin-top: 0;
  margin-bottom: 3vh;
  border: 1px solid;
}

.header>* {
  margin: auto;
}

.title {
  font-size: 2.5vw;
}

.subtitle {
  font-size: 1.25vw;
}

.logo>*>img {
  height: 6vw;
  width: auto;
}

.logout,
select {
  display: grid;
  margin-bottom: 25%;
  grid-template-columns: 2fr 1fr;
  width: 8em;
  border-style: solid;
  border-color: black;
  background-color: white;
  height: 3.5em;
}

.logout>img {
  height: 2em;
  margin: auto;
}

button:hover {
  cursor: pointer;
}
</style>

<template>
    <div class="holder">
        <div class="header">
            <fpam-header />
            <navigation-bar :edition="this.edition"> </navigation-bar>
            <hr>
        </div>
        <div class="content">
            <div v-if="!this.$store.state.loginInfo.login && this.$route.path !== '/register'">
                <login></login>
            </div>

            <div v-else class="slot">
                <!-- @slot Use this slot -->
                <slot>
                </slot>
            </div>
        </div>
        <div class="footer">
            Powered by 
             <a href="https://codelounge.si.usi.ch">
                <img :src="'codelounge-logo.png'" class="camalow" style="height:40px"/>
            </a>
             - through a SA5 project
        </div>

    </div>
</template>

<script>
import FpamHeader from './components/shared/FpamHeader.vue';
import NavigationBar from './components/shared/NavigationBar.vue';
import Login from './components/shared/Login.vue';


export default {
    name: "HomePage",
    data() {
        return { edition: undefined }
    },
    async mounted() {
        if (this.$store.state.loginInfo.login && this.edition == null) {
            const edition = ((new Date()).getFullYear()).toString();
            this.edition = await this.$store.dispatch("fetchEdition", edition)
        }
    },
    components: { FpamHeader, NavigationBar, Login }
}
</script>

<style scoped>

body {
   margin:0;
   padding:0;
   height:100%;
}


a {
    color: black;
    text-decoration: none;
}

hr {
    color: black;
    margin-top: 0;
    margin-bottom: 3vh;
    border: 1px solid;
}

.header>* {
    margin: auto;
}

/* .holder {
    min-height: 100%;
} */

.slot {
    width: 70%;
    min-height: 80vh;
    margin: auto;
}

.holder {
    min-height: 100%;
    position: relative;
    /* height of footer */
}

/* .content {
    padding-bottom: 100px;
} */

/* .footer {
    height: 10vh;
    width: 100%;
    margin-top: 2vh;
} */

.content {
   padding:10px;
   padding-bottom:150px;   /* adjust to footer height */
}

/* .footer {
   position:fixed;
   padding: 5px;
   /* bottom:0; 
   width:100%;
   height: 50px;  /* height of the footer
   background:white;
} */

.camalow {
    transform: translateY(40%);
}
.footer {
  position: fixed;
  left: 0;
  right: 0;
  background: #000;
  bottom: 0;
  /* max-height: 50px; */
  margin-bottom: 5px;
  background-color: white;
  z-index: 100;
  
}

/* .footer {
  background: #ffab62;
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
} */

.logout,
select {
    display: grid;
    margin-bottom: 25%;
    grid-template-columns: 2fr 1fr;
    width: 8em;
    border-style: solid;
    border-color: black;
    background-color: white;
    height: 3.5em;
}

button:hover {
    cursor: pointer;
}

/* .fixed-size-img {
    height: 20px;
} */
</style>


<template>
  <home-page>
    <router-view/>
  </home-page>
</template>
<script>
import HomePage from './HomePage.vue';
export default {
    name: 'Container',
    components: {
        HomePage
    }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
}
</style>

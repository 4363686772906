<template>
  <div>
    <div class="language" v-if="this.language == 'it'">
      <b>IT </b>/<button v-on:click="changeLang('en')">EN</button>
    </div>
    <div class="language" v-if="this.language == 'en'">
      <button v-on:click="changeLang('it')">IT</button>/<b> EN</b>
    </div>
  </div>
</template>

<script>
export default {
  name: "LanguageSwitcher",
  data() {
    return {
      language: 'en'
    }
  },

  async mounted() {
    //set the language of the website to the language in the store
    this.language = this.$store.state.language;
    this.$i18n.locale = this.language;
  },


  methods: {
    /** Called on language change
     *
     * @param {String} language the language that the site is being changed to.
     */
    changeLang(language) {
      //change the language in the store and reload the page to update it over the website
      this.$store.commit("setLanguage", language)
      this.$router.go(0);
    }
  }
}
</script>

<style scoped>
.language>button {
  background-color: white;
  border: none;
  font-size: 12pt;
}

button:hover {
  cursor: pointer;
}</style>

import axios from 'axios'
import { useToast } from 'vue-toastification'

let backend = ""

const toast = useToast()

if (process.env.NODE_ENV != 'development') {
  backend = process.env.VUE_APP_SERVER
}

const backendEndpoint = axios.create({
        baseURL: `${backend}/api/messages`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })


    //if we have a token, give it to them
    backendEndpoint.interceptors.request.use(
      config => {
        if (config.token)
          config.headers['Authorization'] = `Bearer ${config.token}`;
        return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

    backendEndpoint.interceptors.response.use(
      response => response,
      error => {
        const { response} = error
        if (response.status === 400) {
          const errors = response.data
          if (errors.message) {
            const errorObject = JSON.parse(errors.message)
            const errorList = errorObject.errors
            errorList.forEach(errorString => {
              toast.error(errorString)
            });
          }
        }        

        console.log("Error in response")
        console.log(error)
        return error.response;
      });
    
export default backendEndpoint


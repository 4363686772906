<template>
    <div class="body">
        <div class="loginForm">
            <h4>Login</h4>
            <form class="login" @submit.prevent="login()" v-on:keyup.enter="login()">
                <label :for ="username "> {{this.$i18n.t("login.username")}} </label>
                <input type = "text" name ="username" id="username" required />
                <label :for ="password "> Password </label>
                <input type = "password" id="password" name ="password" required />
                <input type="submit" value="Login">
            </form>
            <p>{{this.$i18n.t("login.no")}}</p>
            <router-link to="/register">{{this.$i18n.t("login.register")}}</router-link>
            <p>{{this.$i18n.t("login.student")}}</p>
            <button v-on:click="switchLogin()"> {{this.$i18n.t("login.switch")}} </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Login",
        data() {
            return {
                year: new Date().getFullYear(),
                username:"",
                password:""
            }
        },
        async mounted(){
            //get the current year
            let year = new Date().getFullYear();
            //redirects if the user is logged in
            if(this.$store.state.loginInfo.role == "ROLE_ADMIN"){
                //redirect to the edition page if the user is logged in as an admin
                this.$router.push('/edition')
            } else if (this.$store.state.loginInfo.role == "ROLE_COMPANY"){
                //redirect to the company form if the user is logged in as a company
                this.$router.push('/projects/new')
            }  else if (this.$store.state.loginInfo.role == "ROLE_STUDENT"){
                //redirect to the download page if the user is logged in as an student
                this.$router.push('/booklet/')
            }
        },
        computed:{
            logincheck(){
                return this.$keycloak.authenticated
            }
        },
        watch: {
            /**
             * Checks if the user has logged in with keycloak.
             * 
             * @param {Boolean} newLogin the value of this.$keycloak.authenticated.
             */
            async logincheck(newLogin){
                if(newLogin == true){
                    //new user with keycloak values
                    let user= {
                        username: this.$keycloak.userName,
                        id: this.$keycloak.idToken,
                        token: this.$keycloak.tokenParsed
                    }
                    //Tries to log in. Alert if user isn't in the list of students.
                    await this.$store.dispatch("studentLogin", user).catch(e=>alert(this.$i18n.t("permission.na")))
                    if(this.$store.state.loginInfo.login==true){
                        await this.$store.dispatch("fetchEdition", new Date().getFullYear())
                        this.$router.push('/booklet')
                    }
                }
            }
        },
        methods:{
            /**
             * Called on enter to log in the user.
             */
            async login(){
                let username = document.getElementById("username").value;
                let password = document.getElementById("password").value;
                const user = JSON.stringify({userIdentifier: username, password: password})
                //Tries to log in. Alert if username and/or password isn't found.
                await this.$store.dispatch("login", user).catch(e=>alert(this.$i18n.t("permission.incorrect")))
                //we then fetch the edition for the current year
                await this.$store.dispatch("fetchEdition", this.year)


                //redirect if user is logged in
                if(this.$store.state.loginInfo.role == "ROLE_ADMIN"){
                    this.$router.push('/edition')
                } else if (this.$store.state.loginInfo.role == "ROLE_COMPANY"){
                    this.$router.push('/projects/new')
                } else if (this.$store.state.loginInfo.role == "ROLE_STUDENT"){
                    this.$router.push('/booklet')
                }
            },

            /**
             * Called on student login to use keycloak
             */
            async switchLogin(){
                if(this.$keycloak.authenticated == false){
                    await this.$keycloak.loginFn({checkLoginIframe: false})
                }
            }
        }
    }
</script>

<style scoped>
    .body {
        height: 75vh;
        width: 40%;
        font-size: 1.5vh;
        margin: auto;
    }
    .loginForm {
        border-style: solid;
        margin-bottom: 2vh;
    }

    .login {
        width: 70%;
        margin: auto;
        display: grid;
        grid-template-rows: 3fr 2fr 3fr 2fr;
    }

    .login > label {
        text-align: left;
        width: 100%;
        margin: 0;
        margin-top:1em;
    }

    button{
        color: black;
        border-style: solid;
        border-color: black;
        background-color:white;
        height: 3.5em;
        margin-bottom: 2em;
    }

    input[type="submit" i]{
        color: black;
        border-style: solid;
        border-color: black;
        background-color:white;
        height: 3.5em;
        margin: auto;
        margin-top:2em;
        margin-bottom: 2em;
        width: 5vw;
    }

    button:hover, input[type="submit" i]{
        cursor: pointer;
    }

    input[type="text" i], input[type="password" i]{
        width:90%;
    }
        
</style>